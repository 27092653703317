import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import authenticate from "./dashboard/auth/authenticate";

const routes = [
  {
    path: '',
    component: () => import('./client/views/Index.vue'),
    children: [
      {
        path: '/',
        component: () => import('./client/views/Home.vue')
      },
      {
        path: '/termsAndConditions',
        component: () => import('./client/views/TermsAndConditions')
      },
      {
        path: '/cover',
        component: () => import('./client/views/CoverWizard')
      },

      {
        path: '/quote/:quoteId',
        component: () => import('./client/views/Quote')
      },

      {
        path: '/vehicle-quote/:quoteId',
        component: () => import('./client/views/VehicleQuote')
      },
      {
        path: '/checkout',
        component: () => import('./client/views/Checkout')
      },
      {
        path: '/vehicleCheckout',
        component: () => import('./client/views/VehicleCheckout.vue')
      },
      {
        name: 'personal-details',
        path:'/personal-details/:quoteId',
        component: () => import('./client/views/PersonalDetails')
      },
      {
        name: 'complete-profile',
        path:'/complete-profile/:quoteId',
        component: () => import('./client/views/CompleteCheckout')
      },
      {
        name: 'cover-summary',
        path:'/cover-summary/:quoteId',
        component: () => import('./client/views/CoverSummary.vue')
      },
      {
        name: 'cover-summary',
        path:'/cover-review/:quoteId',
        component: () => import('./client/views/WebCoverSummary.vue')
      },
      {
        path:'/login',
        component: () => import('./client/views/Login')
      },
      {
        path:'/sign-up',
        component: () => import('./client/views/SignUp')
      },
      {
        path:'/forgot-password',
        component: () => import('./client/views/ForgotPassword')
      },
      {
        path:'/verification',
        component: () => import('./client/views/VerificationCode')
      },
      {
        path:'/forgot/password/:token/:email',
        component: () => import('./client/views/PasswordReset')
      },

    ]
  },
  {
    path: '/app',
    beforeEnter: authenticate,
    component: () => import('./dashboard/views/Index.vue'),
    children: [
      {
        path: '/app/home',
        component: () => import('./dashboard/views/Home.vue')
      },
      {
        path: '/app/getQuote',
        component: () => import('./dashboard/views/GetQuote')
      },
      {
        path: '/app/quote',
        component: () => import('./dashboard/views/Quote')
      },
      {
        path: '/app/checkout',
        component: () => import('./dashboard/views/Checkout')
      },
      {
        path: '/app/personal-details/:quoteId',
        component: () => import('./dashboard/views/PersonalDetails')
      },
      {
        path: '/app/policies',
        component: () => import('./dashboard/views/MyPolicies')
      },
      {
        path: '/app/policy/:policyId',
        component: () => import('./dashboard/views/Policy')
      },

        //app/logout
    ]
  }
]

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "open",
  routes
});

export default router
