
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

/*  let firebaseConfig = {

      apiKey: "AIzaSyBQLKbVi08vMhp-WPfzeOaqZmxkZWKB9lQ",
      authDomain: "aicare-staging.firebaseapp.com",
      databaseURL: "https://aicare-staging-default-rtdb.firebaseio.com",
      projectId: "aicare-staging",
      storageBucket: "aicare-staging.appspot.com",
      messagingSenderId: "855576857312",
      appId: "1:855576857312:web:7bf861b3ca3bb9d20ede85"
    };*/

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA3QDY43nVDrbaZ72VIuyMTorJvWVY-74Y",
    authDomain: "motomatic-prod.firebaseapp.com",
    projectId: "motomatic-prod",
    storageBucket: "motomatic-prod.appspot.com",
    messagingSenderId: "471491330684",
    appId: "1:471491330684:web:0003351676c342d90cbff9",
    measurementId: "G-G3EEQ7M8DW"
};


// Your web app's Firebase configuration


// Initialize Firebase
const app = initializeApp(firebaseConfig);

