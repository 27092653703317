import axios from "../../axios"


const quotation_domain = process.env.VUE_APP_QUOTATION_DOMAIN;
const payment_domain = process.env.VUE_APP_PAYMENT_DOMAIN;
const policies_domain = process.env.VUE_APP_POLICIES_DOMAIN;
const payment_motomatic_domain = process.env.VUE_APP_PAYMENT_MOTOMATICS_DOMAIN;


axios.interceptors.request.use(
    config => {

        if(config.url.includes("upload-documents")){
            config.headers['Content-Type'] = 'multipart/form-data';
        }

        else{
            config.headers['Content-Type'] = 'application/json';
        }

        if(config.url.includes("vehicles/me")){
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem("token");
        }

        if(config.url.includes("users")){
            config.headers['x-api-key'] = 'API_KEY_ByEqiMBhaaMJBS61aFfBMxoHtpodb18bZkkyrUFLCrZH72z1iuY';
        }

        config.headers['Access-Control-Allow-Origin'] = '*';

        return config;
    },
    error => {
        Promise.reject(error)
    });


export default {

    changePassword(userData){
        return axios.post(quotation_domain+"auth/complete/password/change", userData)
    },
    getPolicies(email){
        return axios.get(policies_domain+"policyenquiryemail?email="+email+"&page=1&size=20")
    },

    getPolicy(policyId){
        return axios.get(policies_domain+"policydetails/"+policyId)
    },

    getKilometerWallet(){
        return axios.get(quotation_domain+"vehicles/me/"+localStorage.getItem("_id"))
    },

    getQuote(quoteData){
        return axios.post(quotation_domain+"quotes", quoteData)
    },

    generateToken(userData){
        return axios.post(quotation_domain+"auth/generate/token", userData)
    },

    getVehicleMakes(){
        return axios.get(quotation_domain+"vehicles/makes")
    },
    getVehicleModels(vehicleId){
        return axios.get(quotation_domain+"vehicles/models/"+vehicleId)
    },

    getTransactionsByMerchantId(transactionId){
        return axios.get(payment_domain+"tingg/transaction/getbytransid/"+transactionId)
    },

    makePayment(paymentData){
        return axios.post(payment_motomatic_domain+"payments/britam/mpesa/stk", paymentData)
    },

    createUser(userData){
        return axios.post(quotation_domain+"users", userData)
    },

    updatePersonalQuoteInformation(quoteId, quoteData){
        return axios.patch(quotation_domain+"quotes/"+quoteId, quoteData)
    },

    updateValuation(quoteId, quoteData){
        return axios.post(quotation_domain+"valuation/"+quoteId, quoteData)
    },

    getQuoteDetails(quoteId){
        return axios.get(quotation_domain+"quotes/"+quoteId)
    },

    finalizeQuoteDetails(quoteId){
        return axios.post(quotation_domain+"quotes/finalize/"+quoteId)
    },

    makeCardPayment(cardDetails){
        return axios.post(payment_domain+"tingg/direct/card/charge", cardDetails)
    },
    forgotPassword(userData){
        return axios.post(quotation_domain+"auth/forgot/password?product=switch", userData)
    },
    resetPassword(userData){
        return axios.post(quotation_domain+"auth/complete/password/change", userData)
    },
    registerUser(quoteId, quoteData){
        return axios.patch(quotation_domain+"quotes/"+quoteId, quoteData)
    },
    getPolicyBenefits(){
        return axios.get(policies_domain+"quotebenefits")
    },
    uploadDocuments(documents, quoteId){
        return axios.post(quotation_domain+"quotes/upload-documents/"+quoteId, documents, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
}
